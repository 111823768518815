import config from '../config';

interface PromotionData {
    path?: string,
    id?: string,
    links?: Array<string>,
    metaImage?: string | null,
    description?: string | null,
    mainHeader?: string | null,
    leftMenuHeader?: string | null,
};

// Common properties
const leaderboardResultsTitle = 'Bankroll Builder | Leaderboard results';
const leaderboardResultsImage = `${config.webAssetsBaseUrl}/images/promotions/Meta-Tab-7-Leaderboards.png`;
const leaderboardResultsDescription = 'Gold Bankroll Builder. Play-to-Earn. Play for free. Earn $GOLD. Build your crypto bankroll. Win a piece of Phil Ivey WSOP winnings.';

export const drawingWinnersPromotionData: PromotionData = {
    path: '/promotions/drawing-winners',
    id: 'drawing-winners',
    links: [],
    metaImage: `${config.webAssetsBaseUrl}/images/promotions/Meta-Tab-8-Drawing-Winners.png`, // FULL LINK 
    description: leaderboardResultsDescription,
    mainHeader: 'Bankroll Builder | Drawing Winners',
    leftMenuHeader: 'Drawing Winners',
};

export const silverLeaderboardPromotionData: PromotionData = {
    path: '/promotions/leaderboards/silver',
    id: 'silver',
    links: [],
    metaImage: leaderboardResultsImage, // FULL LINK 
    description: leaderboardResultsDescription,
    mainHeader: leaderboardResultsTitle,
    leftMenuHeader: 'Silver LeaderBoard',
};

export const goldLeaderboardPromotionData: PromotionData = {
    path: '/promotions/leaderboards/gold',
    id: 'gold',
    links: [],
    metaImage: leaderboardResultsImage, // FULL LINK 
    description: leaderboardResultsDescription,
    mainHeader: leaderboardResultsTitle,
    leftMenuHeader: 'Gold LeaderBoard',
};

export const sngChaseLeaderboardPromotionData: PromotionData = {
    path: '/promotions/leaderboards/sng-chase',
    id: 'sng-chase',
    links: [],
    metaImage: leaderboardResultsImage, // FULL LINK 
    description: leaderboardResultsDescription,
    mainHeader: leaderboardResultsTitle,
    leftMenuHeader: 'SNG Chase',
};

export const ethGrinderLeaderboardPromotionData: PromotionData = {
    path: '/promotions/leaderboards/eth-grinder',
    id: 'eth-grinder',
    links: [],
    metaImage: leaderboardResultsImage, // FULL LINK 
    description: leaderboardResultsDescription,
    mainHeader: leaderboardResultsTitle,
    leftMenuHeader: 'ETH Grinder',
};

export const availableLeaderboards = [
    silverLeaderboardPromotionData, goldLeaderboardPromotionData, sngChaseLeaderboardPromotionData, ethGrinderLeaderboardPromotionData
];
