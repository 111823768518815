export default {
	leaderboard: {
		silverLeaderboardUrl: process.env.GATSBY_SILVER_LEADERBOARD_URL,
		silverLeaderboardCatalogueUrl: process.env.GATSBY_SILVER_LEADERBOARD_CATALOGUE,
		goldLeaderboardUrl: process.env.GATSBY_GOLD_LEADERBOARD_URL,
		goldLeaderboardCatalogueUrl: process.env.GATSBY_GOLD_LEADERBOARD_CATALOGUE,
		sngChaseLeaderboardChallengeUrl: process.env.GATSBY_SNG_CHASE_LEADERBOARD_URL,
		sngChaseLeaderboardCatalogueUrl: process.env.GATSBY_SNG_CHASE_LEADERBOARD_CATALOGUE,
		ethGrinderLeaderboardUrl: process.env.GATSBY_ETH_GRINDER_LEADERBOARD_URL,
		ethGrinderLeaderboardCatalogueUrl: process.env.GATSBY_ETH_GRINDER_LEADERBOARD_CATALOGUE,
	},
	desktopAppUrl: {
		clientVersionFile: process.env.GATSBY_CLIENT_VERSION_FILE,
		windows: process.env.WINDOWS_DOWNLOAD_LINK,
		linux: process.env.LINUX_DOWNLOAD_LINK,
		mac: process.env.MAC_DOWNLOAD_LINK,
	},
	mailchimp: {
        userId: '8601bc38c20958e388049dade',
        campaigns: {
              subscribeFormId: '8f76340ea4',
              justicesId: 'ef26f5ed7c',
        },
	},
	googleAnalytics: {
		trackingId: process.env.GATSBY_GA_TRACKING_ID,
	},
	externalLinks: {
		ethereum: 'https://ethereum.org/en/',
		binance: 'https://www.bnbchain.world/en/smartChain',
		polygon: 'https://polygon.technology',
		uniswapstaking: 'https://defiwizard.xyz/virtuepoker/staking',
		uniswap: 'https://v2.info.uniswap.org/pair/0xa24501a910e7930148445bcf1fb601a4304e4551',
		pancakestaking: 'https://defiwizard.xyz/virtuepoker/staking/bsc',
		pancake: 'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xe069af87450fb51fc0d0e044617f1c134163e591',
		pancake2: 'https://pancakeswap.finance/info/token/0xe069af87450fb51fc0d0e044617f1c134163e591',
		telegram: 'https://t.me/virtue_poker',
		twitter: 'https://twitter.com/virtue_poker',
		pantera: 'https://panteracapital.com/',
		consensys: 'https://consensys.net/',
		dfg: 'https://www.dfg.group/',
		fenbushi: 'https://www.fenbushicapital.vc/index_en.html',
		digistrats: 'https://digistrats.com/#DigitalStrategies',
		funfair: 'https://funfair.io/',
		superfarm: 'https://superfarm.com/',
		ethernity: 'https://ethernity.io/',
		poa: 'https://www.poa.network/',
		kaleido: 'https://www.kaleido.io/',
		marketacross: 'https://marketacross.com/',
		coindesk: 'https://www.coindesk.com/consensys-project-virtue-poker-raises-5m-ahead-of-mainnet-launch',
		cointelegraph: 'https://cointelegraph.com/news/consensys-backed-poker-platform-secures-5m-investment',
		pokernews: 'https://www.pokernews.com/news/2017/11/virtue-poker-phil-ivey-29355.htm',
		newsbtc: 'https://www.newsbtc.com/news/company/virtue-poker-teams-up-with-superfarm-to-launch-its-initial-dex-offering-on-superstarter',
		cardschat: 'https://www.cardschat.com/news/online-crypto-room-virtue-poker-eyes-may-launch-99515',
		promotions: 'https://blog.virtue.poker/virtue-gaming-play-to-earn-launch/',
		mga: 'https://www.mga.org.mt/',
		kyc: 'https://virtuepoker.zendesk.com/hc/en-us/articles/360012182239',
		mexc: 'https://www.mexc.com/exchange/VPP_USDT',
		latoken: 'https://latoken.com/exchange/VPP_USDT?referral=fpybn7ew',
		hotbit: 'https://nomics.com/exchanges/hotbit/markets/VPP_USDT',
		discord: 'https://discord.gg/pu3e95wKMn',
		iconium: 'http://www.iconium.it/',
	},
	termsAndConditionsLink: process.env.GATSBY_TERMS_AND_CONDITIONS_LINK,
	privacyPolicyLink: process.env.GATSBY_PRIVACY_POLICY_LINK,
	webAssetsBaseUrl: process.env.GATSBY_WEB_ASSETS_URL,
	ghost: {
		contentKey: 'e87195bff8778c337ca4934946',
		url: ' https://virtuepoker.ghost.io',
		filterKey: 'drawingwinners',
		limit: 5,
	},
	disablePromotions: process.env.GATSBY_DISABLE_PROMOTIONS === 'true',
};
