import React from 'react';
import { navigate } from 'gatsby';
import { availableLeaderboards } from '../../../data/promotions';
import config from '../../../config';

class LeaderboardsPage extends React.Component {
    componentDidMount = () => {
        if (window) {
            if (config.disablePromotions) {
                navigate(this.props.pageContext.dynamicDefaultPaths?.defaultPromotionsPath);
            } else {
                navigate(availableLeaderboards[0].path);
            }
        }
    }
    
    render = () => null;
};

export default LeaderboardsPage;
